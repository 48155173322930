import { Button, Col, Row, Tag, Typography } from "antd";
import { MailFilled, PhoneFilled, PushpinFilled } from "@ant-design/icons";

import { Description } from "../../../../shared-components/Components/Property/Description/web";
import { ImagenOptimizada } from "shared-components/Components/Image/_new_web";
import { PhoneButton } from "../../../../shared-components/Components/RealEstateAgent/PhoneButton/web";
import React, { useContext } from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useTheme } from "../../../../shared-components/Styles/ThemeHook";
import { ListingCard } from "shared-components/ViewFragments/SearchPage/ListingCard";
import { ConfigStateContext } from "shared-components/Contexts/Configurations/context";
import { Listing } from "shared-components";

const { Title, Text } = Typography;

export const HomeIcon = ({ className }) => {
	return (
		<svg className={className} viewBox="64 64 896 896" focusable="false" data-icon="home" width="1em" height="1em" fill='currentColor' aria-hidden="true"><path d="M946.5 505L534.6 93.4a31.93 31.93 0 00-45.2 0L77.5 505c-12 12-18.8 28.3-18.8 45.3 0 35.3 28.7 64 64 64h43.4V908c0 17.7 14.3 32 32 32H448V716h112v224h265.9c17.7 0 32-14.3 32-32V614.3h43.4c17 0 33.3-6.7 45.3-18.8 24.9-25 24.9-65.5-.1-90.5z"></path></svg>
	)
}

export const BannerTopCover = ({ inmob }) => {
	const { img_cover, color, name, logo } = inmob;
	const { country_code } = useContext(ConfigStateContext);
	const cover = country_code === 'CO' ? 'https://cdn1.infocasas.com.uy/web/666221a3e3c1e_cover_inmo.jpg' : img_cover;
	const colorBg = country_code === 'CO' ? '#fff' : color;

	return (
		<Row className="perfil-banner-container">
			<Col className="img-cover">
				<ImagenOptimizada alt={name} src={cover} />
			</Col>
			{country_code !== "CO" &&
				<Col className="overlay" style={{ background: color }}></Col>
			}
			<Col className="perfil-logo" style={{ background: colorBg }}>
				<ImagenOptimizada alt={name} src={logo} />
			</Col>
		</Row>
	);
};

export const BannerAffix = ({ inmob }) => {
	const { theme } = useTheme();
	const screen = useBreakpoint();
	const { logo_v2, font_color, logo } = inmob;
	const name = inmob.name.split("-").join(" ");
	const email = inmob.subsidiaries.map(e => e)[0].email;
	const { site_name, country_code } = useContext(ConfigStateContext);
	const banner = logo_v2 ? logo_v2 : logo;
	const type = inmob.type == "desarrollador" ? (country_code === "CO" ? "Constructora" : "Desarrolladora") : "Inmobiliaria";

	return (
		<Row className="perfil-affix-row" gutter={theme.spacing.xlSpacing}>
			{screen.xs && (
				<Col className="perfil-logo">
					<ImagenOptimizada alt={name} src={banner} />
				</Col>
			)}
			<Col className="perfil-info">
				{country_code !== 'CO' && (
					<Col className="verified ">
						<Tag color={"default"} className="verified-tag">
							{`${type} Verificada`}
						</Tag>
					</Col>
				)}
				{(!screen.xs && country_code !== "CO") || country_code === "CO" && (
					<>
						<h1 style={{ color: font_color }} className="property-title heading heading-4">
							{`${type} ${name}`}
						</h1>
					</>
				)}
			</Col>
			<Col className="button-consultar">
				<a
					href={`mailto:${email}?subject=Consulta a ${name} - ${site_name}`}
					className="btn-primary"
				>
					Consultar
				</a>
			</Col>
		</Row>
	);
};

export const PerfilBreadcrumb = ({ inmob }) => {
	const name = inmob.name.split("-").join(" ");
	const { site_name } = useContext(ConfigStateContext);

	return (
		<div className="page-breadcrumb body body-2 body-regular medium">
			<span>Estás en:</span>
			<a href="/">{site_name}</a>
			<span className="breadcrumb-separator">{'>'} </span>
			<span>{name}</span>
		</div>
	);
};

export const PerfilInfoInmob = ({ inmob }) => {
	const { theme } = useTheme();
	const { img_description, logo } = inmob;
	const name = inmob.name.split("-").join(" ");
	const descriptions = inmob.description?.split(".") ?? [];
	const imgDesc = img_description ? img_description : logo

	return (
		<Row gutter={[0, theme.spacing.xlSpacing]} justify="space-between" align="middle">
			<Col xs={{ span: 24, order: 2 }} md={{ span: 8, order: 1 }} className="inmob-left">
				<ImagenOptimizada alt={name} src={imgDesc} />
			</Col>
			<Col xs={{ span: 24, order: 1 }} md={{ span: 15, order: 2 }} className="inmob-right">
				<h1 className="property-title heading heading-4 high">
					{`Acerca de ${name}`}
				</h1>
				{descriptions?.map(
					(desc, i) =>
						desc.length > 0 && (
							<Description
								description={desc + "."}
								hideInformation={false}
								cleanHtml
							/>
						)
				)}
			</Col>
		</Row>
	);
};

export const PerfilSubsidiary = ({ inmob }) => {
	const { country_code } = useContext(ConfigStateContext);
	const { subsidiaries, color, font_color, id, name, masked_phone } = inmob;
	const colorBg = country_code === 'CO' ? '#077be7' : color;
	const colorText = country_code === 'CO' ? '#fff' : font_color;

	return (
		<React.Fragment>
			<h2 className="profile-title heading heading-4 high">Sucursales</h2>

			<div className="perfil-subsidiaries">
				{subsidiaries.map((subsidiary, i) => (
					<div
						className="subsidiary"
						key={"subsidiary_" + i}
						style={
							subsidiaries.length == 1
								? { flexBasis: "50%" }
								: { flexBasis: "calc(50% - 8px)" }
						}
					>
						<div
							className="icon"
							style={{
								background: colorBg,
								color: colorText,
							}}>
							<HomeIcon className='homeIconDesc' />
						</div>
						<div className="info-inmob">
							<h4 className="subhead subhead-semibold medium">{country_code === 'CO' ? name : subsidiary.name}</h4>
							{subsidiary.address && (
								<div className="locations">
									<PushpinFilled />
									<span className="body body-2 body-regular medium">{subsidiary.address}</span>
								</div>
							)}
							{subsidiary.email && (
								<div className="emails">
									<MailFilled />
									<span className="body body-2 body-regular medium">{subsidiary.email}</span>
								</div>
							)}
							<div className="phones">
								<PhoneFilled />
								<PhoneButton
									owner_id={id}
									owner_name={name}
									masked_phone={masked_phone}
									isWpp={false}
									ui_style="text"
								/>
							</div>
						</div>
					</div>
				))}
			</div>
		</React.Fragment>
	);
};

export const PerfilProperties = ({ inmob, properties }: { inmob: any, properties?: Listing[] }) => {
	const { inmoPropsLink } = inmob;
	const propertiesData = properties ?? inmob.properties.data.map(e => e); //Para IC hay que hacer el ajuste de properties

	return <>
		<h2 className="profile-title heading heading-4 high">Propiedades Recientes</h2>
		<div className="properties-container">
			{propertiesData?.map((prop, i) =>
				<ListingCard data={prop} key={i} />
			)}
		</div>

		{inmoPropsLink && (
			<div className="see-more-props">
				<a href={inmoPropsLink} target="_blank" className="btn-secondary">Ver todas las propiedades</a>
			</div>
		)}
	</>
};

export const PerfilButtonContacts = ({ inmob, contact }) => {
	const { theme } = useTheme();
	const name = inmob.name.split("-").join(" ");
	const email = inmob.subsidiaries.map(e => e)[0].email;

	return (
		<React.Fragment>
			<Title level={4} className="perfil-title-contact">
				{`Ponte en Contacto con ${name}`}
			</Title>
			<Row
				gutter={[theme.spacing.lgSpacing, theme.spacing.lgSpacing]}
				justify="space-between"
				align="middle"
				className="perfil-buttons">
				{contact.map((elem, i) => (
					<Col key={"contact_" + i} flex={"1"}>
						<Button
							href={`mailto:${email}?subject=${elem.nameHref} - ${name} - ${process.env.APP_THEME == 'fincaraiz' ? t('FincaRaiz') : t("InfoCasas")}`}
							type="primary"
							block>
							{elem.title}
						</Button>
					</Col>
				))}
			</Row>
		</React.Fragment>
	);
};
