import "../styles.less";

import { Affix, Col, Row, Skeleton } from "antd";

import { PageLayout } from "../../../../src/Components/Layout/web";
import { PropertyCardSkeleton } from "../../../../shared-components/Components/Property/PropertyCard/PropertyCardSkeleton";
import React from "react";
import useBreakpoint from "antd/lib/grid/hooks/useBreakpoint";
import { useTheme } from "../../../../shared-components/Styles/ThemeHook";

export const PerfilSkeleton = () => {
	const { theme } = useTheme();
	const screen = useBreakpoint();

	return (
		<PageLayout classContainer="perfil-inmob-container-skeleton">
			{/* Banner Top Cover Skeleton */}
			{!screen.xs && (
				<Row className="perfil-banner-container-skeleton">
					<Skeleton.Image className="perfil-logo-skeleton" />
				</Row>
			)}
			{/* Content */}
			<div className="content-container-skeleton">
				<Affix className="perfil-fidex-top-skeleton" offsetTop={theme.headerHeight}>
					<div className="container">
						{/* Banner Affix */}
						<Row className="perfil-affix-row-skeleton" gutter={theme.spacing.xlSpacing}>
							{screen.xs && (
								<Col className="perfil-logo-skeleton">
									<Skeleton.Image />
								</Col>
							)}
							<Col className="perfil-info-skeleton">
								<Skeleton active paragraph={{ rows: 0 }} title={true} />

								{!screen.xs && (
									<Skeleton active paragraph={{ rows: 1 }} title={false} />
								)}
							</Col>
							<Col className="button-consultar-skeleton">
								<Skeleton.Button
									active
									shape={"round"}
									style={{ width: "250px" }}
								/>
							</Col>
						</Row>
					</div>
				</Affix>
				<div className="perfil-content-skeleton">
					<Row
						gutter={[0, theme.spacing.xlSpacing]}
						style={{ padding: `${theme.spacing.xlSpacing}px 0` }}>
						{/* Breadscrum */}
						<Col span={24}>
							<div className="container">
								<Skeleton active paragraph={{ rows: 1 }} title={false} />
							</div>
						</Col>
						{/* Info Real Estate */}
						<Col span={24}>
							<div className="container">
								<Row
									gutter={[0, theme.spacing.xlSpacing]}
									justify="space-between"
									align="middle">
									<Col
										xs={{ span: 24, order: 2 }}
										md={{ span: 8, order: 1 }}
										className="inmob-left-skeleton">
										<Skeleton.Image />
									</Col>
									<Col xs={{ span: 24, order: 1 }} md={{ span: 15, order: 2 }}>
										<Skeleton active paragraph={{ rows: 6 }} />
									</Col>
								</Row>
							</div>
						</Col>
						{/* Subsidiary */}
						<Col span={24}>
							<div className="container">
								<Row>
									<Col span={24} md={12}>
										<Skeleton active paragraph={{ rows: 0 }} title={true} />
										<Skeleton avatar paragraph={{ rows: 3 }} />
									</Col>
									{!screen.xs && (
										<Col span={24} md={12}>
											<Skeleton active paragraph={{ rows: 0 }} title={true} />
											<Skeleton avatar paragraph={{ rows: 3 }} />
										</Col>
									)}
								</Row>
							</div>
						</Col>
						{/* Properties */}
						<Col span={24}>
							<div className="container">
								<Row gutter={[theme.spacing.xlSpacing, theme.spacing.xlSpacing]}>
									{[1, 2, 3].map((property, id) => (
										<Col xs={24} sm={12} md={8} key={"property_skeleton_" + id}>
											<PropertyCardSkeleton />
										</Col>
									))}

									<Skeleton.Button
										className="property-button-skeleton"
										active
										shape={"round"}
									/>
								</Row>
							</div>
						</Col>
						{/* Buttons Bottom Contact */}
						<Col span={24}>
							<div className="container">
								<Skeleton
									className="perfil-title-skeleton"
									active
									paragraph={{ rows: 0 }}
									title={true}
								/>

								<Row
									gutter={[theme.spacing.lgSpacing, theme.spacing.lgSpacing]}
									justify="space-between"
									align="middle"
									className="perfil-buttons-skeleton">
									{[1, 2, 3].map((elem, i) => (
										<Col key={"contact_" + i} flex={"1"}>
											<Skeleton.Button active shape={"round"} />
										</Col>
									))}
								</Row>
							</div>
						</Col>
					</Row>
				</div>
			</div>
		</PageLayout>
	);
};
