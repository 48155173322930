import { QUERY_REAL_ESTATE_SINGLE } from "../../../../shared-components/GQL";
import { useQuery } from "@apollo/client";
import { useRouter } from "next/router";

export const useRealEstateSingle = () => {
	const router = useRouter();
	const { perfil_id }: any = router.query;
	const id = perfil_id.split("-")[0];

	const { data, loading, error } = useQuery(QUERY_REAL_ESTATE_SINGLE, {
		variables: {
			id,
		},
	});

	const contact = [
		{ title: "Estoy Vendiento una Propiedad", nameHref: "Vendo Propiedad" },
		{ title: "Quiero Información sobre una Propiedad", nameHref: "Busco Propiedad" },
		{ title: "Consulta General", nameHref: "Consulta General" },
	];

	return {
		error: error,
		loading: loading,
		contact: contact,
		inmob: data?.realEstateSingle,
		inmobId: id,
	};
};
